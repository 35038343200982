export const GET_TOKEN = "/user/gettoken";
export const GET_ZIPCODE_LIST = "/user/zipcodelist";
export const SIGNUP = "/user/register";

export const SIGNIN = "/user/login";
export const FORGET = "/user/forgotpassword";

export const VERIFY_OTP = "/user/verifyotp";
export const RESEND_OTP = "/user/resendotp";

export const USER_PURCHASE_ORDER = "/user/purchaseorder";
export const ADD_HiSTORY_ADDRESS = "/user/addedithistoryaddress";
export const HiSTORY_ADDRESS_LIST = "/user/historyaddresslist";
// export const PURCHAS_LIST = "/user/purchaselist";
export const PAY_SECURITY = "/user/paysecurity";

export const USER_PURCHASELIST = "/user/purchaselist";

export const BRAND_LIST = "/user/brandlist";
export const PURCHASE_LIST = "/user/purchaselist";
export const USER_ADD_TO_CART = "/user/addtocart";
export const USER_CART_LIST = "/user/cartlist";
export const USER_REMOVE_CART = "/user/removecart";
export const USER_UPDATE_CART = "/user/updatecart";
export const USER_PREVIEW_ORDER = "/user/previeworder";
export const USER_DELIVERY_PREVIEW_ORDER = "/user/previewdeliveryorder";

export const USER_ADD_BANK_DETAILS = "/user/addeditbankdetail";
export const CONTACT_US = "/user/contactus";
export const CHANGE_PASSWORD = "/user/changepassword";
export const UPDATE_PROFILE = "/user/updateprofile";

export const USER_EDIT_ADDRESS = "/user/addeditaddress";
export const USER_ADDRESSLIST = "/user/addresslist";
export const USER_DELETEADDRESS = "/user/deleteaddress";
export const USER_DELETEADDRESS_HISTORY = "/user/addedithistoryaddress";

export const SHOW_NOTIFICATIONLIST = "/user/notificationlist";
export const CLEAR_NOTIFICATIONLIST = "/user/clearnotificationlist";
export const READ_NOTIFICATION = "/user/readnotification";

export const USER_DEFAULTADDRESS = "/user/defaultaddress";

export const USER_LOGOUT = "/user/logout";
export const SECURITY_DEPOSITS = "/user/securitydeposits";
export const REFUND_REQUEST = "/user/refundrequest";

export const RESET_PASSWORD = "/user/resetpassword";

export const USER_SELFPICKUP_ORDER = "/user/purchaseorder";
export const USER_DELIVERY_ORDER = "/user/purchasedeliveryorder";
export const USER_PROMO_CODE = "/user/applypromocode";

export const CMS_PAGES = "/user/getcms";
export const FAQS = "/user/faqlist";

// New Added
export const CHECK_VENDOR_AVAILABILITY = "/user/checkvendoravailability";
export const CHECK_VENDOR_FOR_PICKUP = "/user/checkvendorforpickup";

export const CLEAR_CART = "/user/removeallcart";

export const ORDER_LISTING = "/user/orderlist";
export const ORDER_DETAILS = "/user/orderdetail";
export const USER_RATING = "/user/rating";
export const ORDER_CANCEL = "/user/cancelorder";
export const GET_PRODUCTLIST = "/user/productlist";

export const USER_PAYMENT = "/user/payment";
export const TRANSACTION_VERIFY = "/user/transactionverification";

export const USER_PAY_SECURITY_DEPOSIT = "/user/paysecuritydeposit";
export const SECURITY_DEPOSIT_TRANSACTION_VERIFICATION =
  "/user/securitytransactionverification";

export const USER_PARISH_LIST = "/user/parishlist";
export const USER_PARISH_AREA_LIST = "/user/parisharealist";
export const ALL_AVAILABLE_VENDORS = "/user/vendorlist";

export const CHECK_ORDER_STATUS = "/user/checkorderstatus";
