import * as firebase from "firebase/app";
import "firebase/messaging";

const initializedFirebaseApp = firebase.initializeApp({
  // Project Settings => Add Firebase to your web app
  apiKey: "AIzaSyBDykbNVCMrKKP-SqD34nY0RcFK2YZPJVw",
  authDomain: "vernal-dispatch-272118.firebaseapp.com",
  databaseURL: "https://vernal-dispatch-272118.firebaseio.com",
  projectId: "vernal-dispatch-272118",
  storageBucket: "vernal-dispatch-272118.appspot.com",
  messagingSenderId: "370104765208",
  appId: "1:370104765208:web:71c011eb602a760a80ac6d",
  measurementId: "G-2G9CV7XRT0",
});

const messaging = firebase.messaging.isSupported()
  ? firebase.messaging()
  : null;

export { messaging };
