import {
  ADD_TOKEN,
  DELETE_TOKEN,
  GET_INITIAL_TOKEN,
  LOGIN_REQUEEST,
  IS_USER_LOGGEDIN,
  SIGNUP_REQUEST,
  IS_USER_VERIFIED,
  COMPLETE_PROFILE,
  DELETE,
} from "../constants/action-types";

// Action to add token to store
export const addToken = (data) => ({
  type: ADD_TOKEN,
  payload: data,
});

export const deletedToken = () => {
  return {
    type: DELETE_TOKEN,
  };
};

export const getInitialToken = (data) => ({
  type: GET_INITIAL_TOKEN,
  payload: data,
});

export const loginRequest = (data) => {
  return {
    type: LOGIN_REQUEEST,
    payload: data,
  };
};

export const signupRequest = (data) => {
  return {
    type: SIGNUP_REQUEST,
    payload: data,
  };
};

export const checkUser = () => ({
  type: IS_USER_LOGGEDIN,
});

export const userIsVerified = () => ({
  type: IS_USER_VERIFIED,
});

export const deleteAuthState = () => ({
  type: DELETE,
});

export const completeProfileRequest = (data) => ({
  type: COMPLETE_PROFILE,
  payload: data,
});
