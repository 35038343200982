import React, { Suspense } from "react";
import { Redirect } from "react-router";
import LoadingPage from "../components/CommanComponents/LoadingPage";

const SignIn = React.lazy(() => import("../components/pages/SignIn/Index"));
const SignUp = React.lazy(() => import("../components/pages/SignUp/Index"));

const NearestDealer = React.lazy(() =>
  import("../components/pages/NearestDealer/Index")
);
const NearestDealerBeforeLoginInfo = React.lazy(() =>
  import("../components/pages/NearestDealerBeforeLogin/Index")
);
const NearestDealerSelfPickInfo = React.lazy(() =>
  import("../components/pages/NearestDealerSelfPickup/Index")
);
const Products = React.lazy(() => import("../components/pages/Products/Index"));
const UserAppInfo = React.lazy(() =>
  import("../components/pages/UserApp/Index")
);
const DriverAppInfo = React.lazy(() =>
  import("../components/pages/DriverApp/Index")
);
const VendorAppInfo = React.lazy(() =>
  import("../components/pages/VendorApp/Index")
);
const Prices = React.lazy(() => import("../components/pages/Prices/Index"));
const PricesForGuest = React.lazy(() =>
  import("../components/pages/Prices For Guest/Index")
);
const MyCart = React.lazy(() => import("../components/pages/MyCart/Index"));
const TrackDriver = React.lazy(() =>
  import("../components/pages/TrackDriver/Index")
);
const BankInfo = React.lazy(() => import("../components/pages/BankInfo/Index"));
const MyOrderInfo = React.lazy(() =>
  import("../components/pages/MyOrderDeliver/Index")
);
const BulkGas = React.lazy(() => import("../components/pages/BulkGas/Index"));
const ContactUsInfo = React.lazy(() =>
  import("../components/pages/ContactUs/Index")
);
const DeliveryAddress = React.lazy(() =>
  import("../components/pages/DeliveryAddress/Index")
);
const Setting = React.lazy(() => import("../components/pages/Setting/Index"));
const FaqsInfo = React.lazy(() => import("../components/pages/Faqs/Index"));
const FaqForNonLoginUser = React.lazy(() =>
  import("../components/pages/Faqs For Non Login/Index")
);
const PrivacyInfo = React.lazy(() =>
  import("../components/pages/PrivacyPolices/Index")
);
const TermsConditionInfo = React.lazy(() =>
  import("../components/pages/TermsCondition/Index")
);
const NotificationInfo = React.lazy(() =>
  import("../components/pages/Notification/Index")
);
const SecurityDepositInfo = React.lazy(() =>
  import("../components/pages/SecurityDeposit/Index")
);
const Cylinder = React.lazy(() =>
  import("../components/pages/CylinderGasInformation/Index")
);

const InstructionsToUse = React.lazy(() =>
  import("../components/pages/IntroductionToUse/Index")
);

const EmergencyPractices = React.lazy(() =>
  import("../components/pages/EmergencyPractices/Index")
);

const Training = React.lazy(() => import("../components/pages/Training/Index"));

const FAQSATCYLINDERINFO = React.lazy(() =>
  import("../components/pages/FAQForCylinderInfoPage/Index")
);

const PreviewOrderInfo = React.lazy(() =>
  import("../components/pages/PreviewOrder/Index")
);
const PageUnderMaintenance = React.lazy(() =>
  import("../components/CommanComponents/PageUnderMaintenance")
);
// const NearestDealerPriceInfo = React.lazy(() =>
//   import("../components/pages/NearestDealerPrice/Index")
// );
// const SearchLocationInfo = React.lazy(() =>
//   import("../components/pages/SearchLocation/Index")
// );
// const AboutUs = React.lazy(() => import("../components/pages/AboutUs/Index"));
// const Accessories = React.lazy(() =>
//   import("../components/pages/Accessories/Index")
// );
// const MyOrderOngoingInfo = React.lazy(() =>
//   import("../components/pages/MyOrderOngoing/Index")
// );
// const MyCartSelf = React.lazy(() =>
//   import("../components/pages/MyCartSelfPickup/Index")
// );
// const CylinderGas = React.lazy(() =>
//   import("../components/pages/CylinderGas/Index")
// );
// const MyOrderRatingInfo = React.lazy(() =>
//   import("../components/pages/MyOrderDeliveredWithRating/Index")
// );
// const ModalsInfo = React.lazy(() => import("../components/pages/Modals/Index"));
// const OTP1 = React.lazy(() => import("../components/pages/OTP/Index"));
// const Maps = React.lazy(() => import("../components/Map/Index"));
// const PreviewOrderSelfPickupInfo = React.lazy(() =>
//   import("../components/pages/PreviewOrderSelfPickup/Index")
// );

export const appRoutes = [
  {
    exact: true,
    path: "/",
    component: () => (
      <Suspense fallback={<LoadingPage />}>
        <PricesForGuest />
      </Suspense>
    ),
  },
  {
    exact: true,
    path: "/nearest-dealer-for-guest",
    component: () => (
      <Suspense fallback={<LoadingPage />}>
        <NearestDealerBeforeLoginInfo />
      </Suspense>
    ),
  },
  {
    //exact: true,
    path: "/terms-conditions",
    component: () => (
      <Suspense fallback={<LoadingPage />}>
        <TermsConditionInfo />
      </Suspense>
    ),
  },
  {
    exact: true,
    path: "/page-under-maintenance",
    component: () => (
      <Suspense fallback={<LoadingPage />}>
        <PageUnderMaintenance />
      </Suspense>
    ),
  },
  {
    //exact: true,
    path: "/sign-up",
    component: () => (
      <Suspense fallback={<LoadingPage />}>
        <SignUp />
      </Suspense>
    ),
  },
  {
    //exact: true,
    path: "/privacy-policy",
    component: () => (
      <Suspense fallback={<LoadingPage />}>
        <PrivacyInfo />
      </Suspense>
    ),
  },
  {
    //exact: true,
    path: "/sign-in",
    component: () => (
      <Suspense fallback={<LoadingPage />}>
        <SignIn />
      </Suspense>
    ),
  },
  {
    //exact: true,
    path: "/products",
    component: () => (
      <Suspense fallback={<LoadingPage />}>
        <Products />
      </Suspense>
    ),
  },
  {
    // exact: true,
    path: "/cylinder-gas-information",
    component: () => (
      <Suspense fallback={<LoadingPage />}>
        <Cylinder />
      </Suspense>
    ),
  },
  {
    // exact: true,
    path: "/instructions-to-use",
    component: () => (
      <Suspense fallback={<LoadingPage />}>
        <InstructionsToUse />
      </Suspense>
    ),
  },
  {
    // exact: true,
    path: "/emergency-practices",
    component: () => (
      <Suspense fallback={<LoadingPage />}>
        <EmergencyPractices />
      </Suspense>
    ),
  },
  {
    // exact: true,
    path: "/training",
    component: () => (
      <Suspense fallback={<LoadingPage />}>
        <Training />
      </Suspense>
    ),
  },
  {
    // exact: true,
    path: "/faqs-for-leafgas",
    component: () => (
      <Suspense fallback={<LoadingPage />}>
        <FAQSATCYLINDERINFO />
      </Suspense>
    ),
  },

  {
    //exact: true,
    path: "/nearest-dealer",
    component: () => (
      <Suspense fallback={<LoadingPage />}>
        <NearestDealer />
      </Suspense>
    ),
  },
  {
    //exact: true,
    path: "/prices",
    component: () => (
      <Suspense fallback={<LoadingPage />}>
        <Prices />
      </Suspense>
    ),
  },
  // {
  //   exact: true,
  //   path: "/pricesforguest",
  //   component: () => (
  //     <Suspense fallback={<LoadingPage />}>
  //       <PricesForGuest />
  //     </Suspense>
  //   ),
  // },
  {
    //exact: true,
    path: "/user-app",
    component: () => (
      <Suspense fallback={<LoadingPage />}>
        <UserAppInfo />
      </Suspense>
    ),
  },
  {
    //exact: true,
    path: "/vendor-app",
    component: () => (
      <Suspense fallback={<LoadingPage />}>
        <VendorAppInfo />
      </Suspense>
    ),
  },
  {
    //exact: true,
    path: "/driver-app",
    component: () => (
      <Suspense fallback={<LoadingPage />}>
        <DriverAppInfo />
      </Suspense>
    ),
  },
  {
    //exact: true,
    path: "/faqs",
    component: () => (
      <Suspense fallback={<LoadingPage />}>
        <FaqsInfo />
      </Suspense>
    ),
  },
  {
    //exact: true,
    path: "/faqsforguest",
    component: () => (
      <Suspense fallback={<LoadingPage />}>
        <FaqForNonLoginUser />
      </Suspense>
    ),
  },
  // {
  //   exact: true,
  //   path: "/",
  //   component: () => (
  //     <Suspense fallback={<LoadingPage />}>
  //       <Home />
  //     </Suspense>
  //   ),
  // },
];

export const privateRoutes = [
  {
    //exact: true,
    path: "/contact-us",
    component: () => (
      <Suspense fallback={<LoadingPage />}>
        <ContactUsInfo />
      </Suspense>
    ),
  },
  {
    //exact: true,
    path: "/nearest-dealer-self-pickup",
    component: () => (
      <Suspense fallback={<LoadingPage />}>
        <NearestDealerSelfPickInfo />
      </Suspense>
    ),
  },
  {
    // exact: true,
    path: "/my-order/:order_id?",
    component: () => (
      <Suspense fallback={<LoadingPage />}>
        <MyOrderInfo />
      </Suspense>
    ),
  },
  {
    //exact: true,
    path: "/preview-order",
    component: () => (
      <Suspense fallback={<LoadingPage />}>
        <PreviewOrderInfo />
      </Suspense>
    ),
  },
  {
    //exact: true,
    path: "/delivery-address",
    component: () => (
      <Suspense fallback={<LoadingPage />}>
        <DeliveryAddress />
      </Suspense>
    ),
  },
  {
    //exact: true,
    path: "/security-deposit",
    component: () => (
      <Suspense fallback={<LoadingPage />}>
        <SecurityDepositInfo />
      </Suspense>
    ),
  },
  {
    //exact: true,
    path: "/my-cart",
    component: () => (
      <Suspense fallback={<LoadingPage />}>
        <MyCart />
      </Suspense>
    ),
  },
  {
    //exact: true,
    path: "/bank-info",
    component: () => (
      <Suspense fallback={<LoadingPage />}>
        <BankInfo />
      </Suspense>
    ),
  },
  {
    //exact: true,
    path: "/bulk-gas",
    component: () => (
      <Suspense fallback={<LoadingPage />}>
        <BulkGas />
      </Suspense>
    ),
  },
  {
    //exact: true,
    path: "/settings",
    component: () => (
      <Suspense fallback={<LoadingPage />}>
        <Setting />
      </Suspense>
    ),
  },
  {
    //exact: true,
    path: "/notifications",
    component: () => (
      <Suspense fallback={<LoadingPage />}>
        <NotificationInfo />
      </Suspense>
    ),
  },
  {
    //exact: true,
    path: "/track-driver",
    component: () => (
      <Suspense fallback={<LoadingPage />}>
        <TrackDriver />
      </Suspense>
    ),
  },
  // {
  //   exact: true,
  //   path: "/search-location-info",
  //   component: () => (
  //     <Suspense fallback={<LoadingPage />}>
  //       <SearchLocationInfo />
  //     </Suspense>
  //   ),
  // },

  // {
  //   exact: true,
  //   path: "/otp",
  //   component: () => (
  //     <Suspense fallback={<LoadingPage />}>
  //       <OTP1 />
  //     </Suspense>
  //   ),
  // },

  // {
  //   //exact: true,
  //   path: "/nearest-dealer-price-info",
  //   component: () => (
  //     <Suspense fallback={<LoadingPage />}>
  //       <NearestDealerPriceInfo />
  //     </Suspense>
  //   ),
  // },
  // {
  //   //exact: true,
  //   path: "/my-cart-self-pickup",
  //   component: () => (
  //     <Suspense fallback={<LoadingPage />}>
  //       <MyCartSelf />
  //     </Suspense>
  //   ),
  // },
  // {
  //   //exact: true,
  //   path: "/my-order-rating",
  //   component: () => (
  //     <Suspense fallback={<LoadingPage />}>
  //       <MyOrderRatingInfo />
  //     </Suspense>
  //   ),
  // },
  // {
  //   //exact: true,
  //   path: "/my-order-ongoing",
  //   component: () => (
  //     <Suspense fallback={<LoadingPage />}>
  //       <MyOrderOngoingInfo />
  //     </Suspense>
  //   ),
  // },
  // {
  //   //exact: true,
  //   path: "/preview-order-self-pickup",
  //   component: () => (
  //     <Suspense fallback={<LoadingPage />}>
  //       <PreviewOrderSelfPickupInfo />
  //     </Suspense>
  //   ),
  // },
  // {
  //   //exact: true,
  //   path: "/accessories",
  //   component: () => (
  //     <Suspense fallback={<LoadingPage />}>
  //       <Accessories />
  //     </Suspense>
  //   ),
  // },
  // {
  //   //exact: true,
  //   path: "/cylinder-gas",
  //   component: () => (
  //     <Suspense fallback={<LoadingPage />}>
  //       <CylinderGas />
  //     </Suspense>
  //   ),
  // },
  // {
  //   exact: true,
  //   path: "/search-location-info",
  //   component: () => (
  //     <Suspense fallback={<LoadingPage />}>
  //       <SearchLocationInfo />
  //     </Suspense>
  //   ),
  // },
  // {
  //   //exact: true,
  //   path: "/about-us",
  //   component: () => (
  //     <Suspense fallback={<LoadingPage />}>
  //       <AboutUs />
  //     </Suspense>
  //   ),
  // },
  // {
  //   //exact: true,
  //   path: "/my-cart",
  //   component: () => (
  //     <Suspense fallback={<LoadingPage />}>
  //       <MyCart />
  //     </Suspense>
  //   ),
  // },
];
