import React, { Component } from "react";

import { HashLink as HLink } from "react-router-hash-link";
import { Link } from "react-router-dom";
//import './home.css'

class BeforeLoginHeader extends Component {
  render() {
    return (
      <React.Fragment>
        <nav className="navbar navbar-expand-lg main-landing">
          <div className="container">
            <div className="d-flex align-items-center mr-auto">
              <Link className="navbar-brand" to="/">
                <img
                  src="/assets/img/logo-white.svg"
                  className="d-none d-md-inline"
                  height="45px"
                  alt="logo-white"
                />
                <div className="logo-icon d-inline-block d-md-none">
                  <img src="/assets/img/logo-icon-white.png" alt="logo-white" />
                </div>
              </Link>
            </div>
            <div className="right order-1 order-lg-2">
              <ul className="navbar-nav align-items-center flex-row">
                <li className="nav-item">
                  <Link className="nav-link" to="/sign-in">
                    Login/Sign Up
                  </Link>
                </li>
              </ul>
            </div>
            <div
              className="navbar-collapse order-3 order-lg-1 collapse justify-content-end"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav">
                <li className="nav-item dropdown gas-dropdown active">
                  <Link
                    className="nav-link dropdown-toggle smoothScroll"
                    to="#"
                    data-toggle="dropdown"
                    aria-haspopup="true"
                    aria-expanded="false"
                  >
                    Cylinder Gas
                  </Link>
                  <div className="dropdown-menu dropdown-menu-right m-0">
                    <div className="log-arrow-up"></div>
                    <Link className="dropdown-item" to="/">
                      Prices
                    </Link>
                    <Link className="dropdown-item" to="/nearest-dealer">
                      Nearest Dealer
                    </Link>
                    <Link className="dropdown-item" to="/products">
                      Products
                    </Link>
                    <Link
                      className="dropdown-item"
                      to="/cylinder-gas-information"
                    >
                      Cylinder gas information
                    </Link>
                    <Link className="dropdown-item" to="/user-app">
                      Mobile App
                    </Link>
                  </div>
                </li>
                <li className="nav-item">
                  <HLink
                    className="nav-link smoothScroll"
                    to="#how-it-works"
                    scroll={(el) => el.scrollIntoView({ behavior: "smooth" })}
                  >
                    {" "}
                    How it Works
                  </HLink>
                </li>
                <li className="nav-item">
                  <HLink
                    className="nav-link smoothScroll"
                    to="#contact-us"
                    scroll={(el) => el.scrollIntoView({ behavior: "smooth" })}
                  >
                    {" "}
                    Contact Us
                  </HLink>
                </li>
              </ul>
            </div>
            <button
              className="navbar-toggler order-2"
              type="button"
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <img src="/assets/img/menu-white.png" alt="menu img" />
            </button>
          </div>
        </nav>
      </React.Fragment>
    );
  }
}

export default BeforeLoginHeader;
