import { ADD_CYLINDER_DATA } from "../constants/action-types";

const initialState = {};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case ADD_CYLINDER_DATA:
      return { ...state, ...payload };
    default:
      return state;
  }
};
