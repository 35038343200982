import React from "react";
import { Route, Redirect } from "react-router-dom";
import { checkUser } from "../../util/utils";

function PrivateRoute({ component: Component, ...rest }) {
  // console.log("rest", rest, Component);
  return (
    <>
      <Route
        {...rest}
        render={(props) =>
          checkUser() ? (
            <Component {...props} />
          ) : (
            <Redirect
              to={{ pathname: "/sign-in", state: { from: props.location } }}
            />
          )
        }
      />
    </>
  );
}

export default PrivateRoute;
