import React from "react";
import { Modal } from "react-bootstrap";

class AskForNotificationModal extends React.Component {
  render() {
    const { hide_modal, status, retry } = this.props;
    return (
      <Modal
        show={status && status}
        onHide={hide_modal}
        className={status ? "modal fade show" : "modal fade "}
        aria-labelledby="contained-modal-title-vcenter"
        centered
        size="lg"
      >
        <Modal.Body>
          <div className="table-responsive">
            <h4>Opps!</h4>
            {Notification.permission === "denied" ? (
              <>
                <h6>
                  Currently, the site is blocked from sending notifications.
                  Please unblock the same in your browser settings.
                </h6>
                <div>
                  <img
                    height={200}
                    className="img-responsive"
                    src="/assets/img/new-updated/allow-notifications.png"
                  />
                  <p className="text-muted mt-2">
                    Steps to enable notification in Chrome
                  </p>
                  <ul>
                    <li>Click on the ‘Lock’ symbol next to the website URL</li>
                    <li>
                      You should now be able to see a ‘Notifications’ field
                      saying ‘Block’
                    </li>
                    <li>
                      Click on the 2 opposite pointing arrows next to “Block”.
                      Select “Always allow on this site”
                    </li>
                    <li>
                      You will now see a message saying “To apply your updated
                      settings to this site, reload this page.” Click on the
                      Reload button to reload the website and unblock
                      notifications from the website
                    </li>
                  </ul>
                  <p className="text-muted mt-2">
                    Steps to enable notification in Firefox
                  </p>
                  <ul>
                    <li>
                      Click on the Document box symbol and then click on the ‘x’
                      icon next to ‘Blocked’ against ‘Receive Notifications’ and
                      reload the page.
                    </li>
                    <li>
                      You will now see a pop up with the opt-in for
                      notifications. Click on ‘Allow Notifications’ and you will
                      start receiving future notifications from this website.
                    </li>
                  </ul>
                  <p>
                    <a
                      className="selected-address"
                      href="https://help.vwo.com/hc/en-us/articles/360007700494-How-To-Unblock-Notifications-From-A-Website-VWO-Help-"
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Learn More
                    </a>
                  </p>
                </div>
              </>
            ) : (
              <h6>You need to allow this site to send notifications</h6>
            )}
          </div>
        </Modal.Body>
        <Modal.Footer className="text-right">
          <button
            type="button"
            onClick={hide_modal}
            className="btn btn-primary-theme-outline w-140 mr-3"
            data-dismiss="modal"
          >
            Not Now
          </button>
          <button
            type="button"
            onClick={retry}
            className="btn btn-primary-theme "
            data-dismiss="modal"
          >
            {Notification.permission === "denied" ? "Unblock & Retry" : "Retry"}
          </button>
        </Modal.Footer>
      </Modal>
    );
  }
}
export default AskForNotificationModal;
