import {
  ADD_TOKEN,
  IS_USER_LOGGEDIN,
  DELETE_TOKEN,
  IS_USER_VERIFIED,
  LOGIN_REQUEEST,
  SIGNUP_REQUEST,
} from "../constants/action-types";
import {
  getSaveToken,
  saveIsLoggedIn,
  checkUser,
  objectProcessor,
  saveToken,
} from "../util/utils";

// Reducer to add article
const initilState = {
  token: "",
  isUserLoggedIn: false,
  isVerified: false,
  userInfo: {},
};

// const deleteArticle = (articles, index) => {
//   if (Array.isArray(articles)) {
//     articles.splice(index, 1);
//     return articles;
//   } else {
//     return articles;
//   }
// };

let isUserLoggedIn = false;

const authReducer = (state = initilState, action) => {
  // console.log("authReducer", action);
  switch (action.type) {
    case ADD_TOKEN:
      saveToken(action.payload);
      let token = getSaveToken();
      return objectProcessor(state, { token });

    case LOGIN_REQUEEST:
      // console.log("action:", action);

      //setCompleteProfile(is_complete_profile);
      //saveAsVerified(mobile_verified);
      saveIsLoggedIn(true);
      isUserLoggedIn = checkUser();
      localStorage.setItem("userInfo", action.payload.user_info);
      return {
        ...state,
        userInfo: action.payload.user_info,
      };
    case IS_USER_LOGGEDIN:
      isUserLoggedIn = checkUser();
      // console.log(isUserLoggedIn);
      return { ...state };
    case SIGNUP_REQUEST:
      saveIsLoggedIn(true);
      isUserLoggedIn = checkUser();
      saveToken(action.payload);
      localStorage.setItem(
        "userInfo",
        JSON.stringify(action.payload.user_info)
      );
      return {
        ...state,
        isVerified: true,
        ...action.payload.user,
        userInfo: action.payload.user_info,
      };

    case DELETE_TOKEN:
      // console.log("token deleted");
      localStorage.removeItem("isLoggedIn");
      localStorage.removeItem("userInfo", action.payload.user);
      return {
        ...state,
        isVerified: false,
        ...action.payload.user,
        userInfo: {},
      };

    case IS_USER_VERIFIED:
      return {
        ...state,
        isUserLoggedIn,
        is_complete_profile: action.payload.user.is_complete_profile,
        mobile_verified: action.payload.user.mobile_verified,
      };
    default:
      return state;
  }
};

export default authReducer;
