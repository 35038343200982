import {
  SHOW_HISTORY_ADDRESS,
  ADD_HISTORY_ADDRESS,
} from "../constants/action-types";

const initialState = {
  address: [],
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case SHOW_HISTORY_ADDRESS:
      // console.log("show reducer data :", payload)
      return { ...state, address: payload.address_history };

    case ADD_HISTORY_ADDRESS:
      // console.log("reducer data :", payload)
      return { ...state, address: [...state.address, payload] };
    default:
      return state;
  }
};
