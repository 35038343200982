import {
  SHOW_ADDRESS,
  DELETE_ADDRESS,
  ADD_ADDRESS,
  EDIT_ADDRESS,
  CLEAR_ADDRESS,
} from "../constants/action-types";

const initialState = {
  address: [],
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case SHOW_ADDRESS:
      return { ...state, address: payload.address_list };
    case DELETE_ADDRESS:
      return {
        ...state,
        address: state.address.filter((items) => items.id !== payload),
      };

    case ADD_ADDRESS:
      return { ...state, address: [...state.address, payload] };

    case EDIT_ADDRESS:
      const idAddress = payload.id;

      const index = state.address.findIndex(
        (address) => address.id === idAddress
      );

      // console.log("index :", ...state.address[index])
      state.address[index] = payload;

      return {
        ...state,
        address: [...state.address],
      };

    case CLEAR_ADDRESS:
      return {
        address: [],
      };

    default:
      return state;
  }
};
