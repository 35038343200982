import {
  SHOW_ADDRESS,
  DELETE_ADDRESS,
  ADD_ADDRESS,
  EDIT_ADDRESS,
  CLEAR_ADDRESS,
} from "../constants/action-types";

export const showAddress = (payload) => {
  return {
    type: SHOW_ADDRESS,
    payload,
  };
};

export const deleteAddress = (payload) => {
  return {
    type: DELETE_ADDRESS,
    payload,
  };
};

export const addAddress = (payload) => {
  return {
    type: ADD_ADDRESS,
    payload,
  };
};
export const editAddress = (payload) => {
  return {
    type: EDIT_ADDRESS,
    payload,
  };
};
export const clearAddress = (payload) => {
  return {
    type: CLEAR_ADDRESS,
  };
};
