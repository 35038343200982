import {
  SHOW_PREVIEW_LIST,
  ADD_PREVIEW_LIST,
  UPDATE_PREVIEW_LIST,
  DELETE_PREVIEW_LIST,
} from "../constants/action-types";

const initialState = {
  previewList: [],
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case SHOW_PREVIEW_LIST:
      // console.log("check data :", payload);
      return { ...state, previewList: payload };

    case UPDATE_PREVIEW_LIST:
      // console.log("UPDATE_TO_CART:", payload, state.previewList.preview_list);
      if (state.previewList.preview_list) {
        const array = state.previewList.preview_list;
        const findindex = array.findIndex((item) => item.id == payload.id);
        //const fin = array[findindex];
        // console.log("UPDATE_TO_CART:", state.previewList.preview_list[findindex]);
        state.previewList.preview_list[findindex].quantity = payload.quantity;
        const pri = payload.price;
        const prisave = pri.toLocaleString();
        state.previewList.preview_list[findindex].price = prisave;
      }
      return {
        ...state,
        previewList: { preview_list: [...state.previewList.preview_list] },
      };

    case DELETE_PREVIEW_LIST:
      // console.log("check remove preview list :", payload);
      return {
        ...state,
        previewList: {
          preview_list: state.previewList.preview_list.filter(
            (cart) => cart.id !== payload
          ),
        },
      };
    //  case EDIT_PREVIEW_LIST:
    //   // console.log("check data :", payload);
    //    return { ...state, previewList: payload };
    default:
      return state;
  }
};
