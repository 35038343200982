import React from "react";
import { Switch, Route, BrowserRouter as Router } from "react-router-dom";
import { appRoutes, privateRoutes } from "./constants/routes";
import PrivateRoute from "./components/CommanComponents/securedRoutes";
import PageNotFound from "./components/CommanComponents/PageNotFound";
import "react-datepicker/dist/react-datepicker.css";
import { messaging } from "./util/init-fcm";
import { notification_type } from "./constants/fcm-types";
import { connect } from "react-redux";
import * as actions from "./constants/mapDispatchToProps";
import * as stateToProps from "./constants/mapStateToProps";
import AskForNotificationModal from "./components/CommanComponents/askForNotificationModal";
import { apiCall, METHOD } from "./constants/baseUrl";
import { GET_TOKEN } from "./constants/api";
import { saveToken } from "./util/utils";
import { HashLink } from "react-router-hash-link";

class App extends React.Component {
  state = {
    isNotificationModalOpen: false,
    hasToken: false,
  };

  async componentDidMount() {
    window.scrollTo(0, 0);
    console.log(
      "\n\n\n\n\n You are on ",
      process.env.NODE_ENV,
      " Server \n\n\n\n"
    );
    const fcmToken = localStorage.getItem("FCM_TOKEN");
    let self = this;

    if (fcmToken !== "") {
      if (messaging !== null) {
        var permissionGranted = false;
        try {
          if (Notification.permission !== "granted") {
            // const oldFCMToken = localStorage.getItem("FCM_TOKEN");
            // localStorage.removeItem("FCM_TOKEN");
            // messaging.deleteToken(oldFCMToken).then(() => {
            //   console.log("Old Token Deleted");
            // });
            await Notification.requestPermission();
          }
          if (localStorage.getItem("FCM_TOKEN") !== null) {
            permissionGranted = true;
          } else {
            const token = await messaging.getToken();
            if (process.env.NODE_ENV === "development") {
              console.log("FCM Token", token);
            }
            localStorage.setItem("FCM_TOKEN", token);
            self.setState({
              hasToken: true,
            });
          }
        } catch (err) {
          console.log("Unable to get permission to notify.", err);
          self.setState({
            hasToken: false,
          });
        }
        // messaging
        //   .requestPermission()
        //   .then(async function () {
        //     const token = await messaging.getToken();
        //     if (process.env.NODE_ENV === "development") {
        //       console.log("FCM Token", token);
        //     }
        //     localStorage.setItem("FCM_TOKEN", token);
        //     self.setState({
        //       hasToken: true,
        //     });
        //   })
        //   .catch(function (err) {
        //     console.log("Unable to get permission to notify.", err);
        //     self.setState({
        //       isNotificationModalOpen: true,
        //       hasToken: false,
        //     });
        //   });
      }
    }
    try {
    navigator.serviceWorker.addEventListener("message", ({ data }) => {
      
        if (process.env.NODE_ENV === "development") {
          console.log("FCM_DATA", data);
        }
        const notificationData = data["firebase-messaging-msg-data"].data;
        var notificationType =
          notification_type[notificationData?.notification_type];
        sessionStorage.setItem(notificationType, notificationData?.message);
        sessionStorage.setItem(
          "NotificationPayload",
          JSON.stringify(notificationData)
        );
      
    });
  } catch (error) {
    console.log("error", error)
  }

    const isLoggedIn = localStorage.getItem("isLoggedIn");
    if (isLoggedIn) {
      //this.props.history.push("/search-location-info");
    } else
      apiCall(
        GET_TOKEN,
        {},
        (data) => {
          // console.log("tokendata", data);
          saveToken(data);
        },
        (err) => console.log(err),
        METHOD.GET
      );
  }

  // hide_notificationPermission_popup = async () => {
  //   this.setState({ isNotificationModalOpen: false });
  // };

  // retry_to_get_notification_permission = async () => {
  //   if (this.state.hasToken) {
  //     this.setState({ isNotificationModalOpen: false });
  //   } else {
  //     this.setState({ isNotificationModalOpen: false });
  //     await Notification.requestPermission();
  //     if (Notification.permission === "granted") {
  //       const token = await messaging.getToken();
  //       if (process.env.NODE_ENV === "development") {
  //         console.log("FCM Token", token);
  //       }
  //       this.setState({ isNotificationModalOpen: false, hasToken: true });
  //       localStorage.setItem("FCM_TOKEN", token);
  //     } else {
  //       setTimeout(() => {
  //         this.setState({ isNotificationModalOpen: true });
  //       }, 1000);
  //     }
  //   }
  // };

  render() {
    return (
      <div id="root-container">
        <Router>
          <Switch>
            {appRoutes.map((routeProps, index) => (
              <Route key={index} {...routeProps} />
            ))}

            {privateRoutes.map((routeProps, index) => {
              return <PrivateRoute key={index} {...routeProps} />;
            })}
            <Route component={PageNotFound} />
          </Switch>
          <HashLink
            id="back-to-top"
            to="#root-container"
            className="btn btn-primary-theme smoothScroll scroll-top-fix-btn"
            role="button"
            scroll={(el) => el.scrollIntoView({ behavior: "smooth" })}
          >
            <i className="zmdi zmdi-long-arrow-up zmdi-hc-lg"></i>
          </HashLink>
        </Router>
      </div>
    );
  }
}

export default connect(
  (state) =>
    stateToProps.appMapStateToProps(
      [
        stateToProps.showFCMPushNotifications,
        stateToProps.clearFCMNotification,
        stateToProps.updateFCMNotification,
      ],
      state
    ),
  actions.appMapDispatchToProps([
    actions.showFCMPushNotificationsDispatchToProps,
    actions.clearFCMPushNotificationsDispatchToProps,
    actions.updateFCMPushNotificationsDispatchToProps,
  ])
)(App);
