import React from "react";

export default function LoadingPage() {
  return (
    <div className="loader-container">
      <div className="loader-logo load8">
        <div className="loader">Loading...</div>
      </div>
    </div>
  );
}
