import {
  ADD_TO_CART,
  REMOVE_FROM_CART,
  CLEAR_CART,
  SHOW_TO_CART,
  UPDATE_TO_CART,
} from "../constants/action-types";

const initialState = {
  cart: {
    cart_item_list: [],
  },
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case SHOW_TO_CART:
      // console.log("check data :", payload);
      return { ...state, cart: payload };

    case UPDATE_TO_CART:
      // console.log("UPDATE_TO_CART:", payload);
      if (state.cart.cart_item_list) {
        const array = state.cart.cart_item_list;
        const findindex = array.findIndex((item) => item.id == payload.id);
        //const fin = array[findindex];
        //console.log("UPDATE_TO_CART:", state.cart.cart_item_list[findindex]);
        state.cart.cart_item_list[findindex].quantity = payload.quantity;
        const pri = payload.price;
        const prisave = pri.toLocaleString();
        state.cart.cart_item_list[findindex].price = prisave;
      }
      return {
        ...state,
        cart: { cart_item_list: [...state.cart.cart_item_list] },
      };
    case ADD_TO_CART:
      return {
        ...state,
        cart: {
          ...state.cart,
          cart_item_list: [...state.cart.cart_item_list, payload],
        },
      };
    case REMOVE_FROM_CART:
      // console.log("REMOVE_FROM_CART:", payload, state);
      return {
        ...state,
        cart: {
          cart_item_list: state.cart.cart_item_list.filter(
            (cart) => cart.id !== payload
          ),
        },
      };
    case CLEAR_CART:
      return { ...state, cart: { ...state.cart, cart_item_list: [] } };
    default:
      return state;
  }
};
