import React, { Component } from "react";
import { Link } from "react-router-dom";
import Header from "../Header/Index";
import Footer from "../Footer/index";
// import Login from "../pages/SignIn";

export default class PageNotFound extends Component {
  render() {
    return (
      <div className="wrapper">
        <Header />
        <div className="main-container">
          <div
            className="section-page d-flex align-items-center"
            style={{ minHeight: "calc(100vh - 105px)" }}
          >
            <div className="container">
              <div className="page-not-found-content text-center">
                <p>Oops!</p>
                <h1>
                  4<i className="fas fa-frown"></i>4
                </h1>
                <p>Page not found!</p>
                <div className="form-action mt-4">
                  <Link to="/" type="button" className="btn btn-primary btn-lg">
                    Go to Homepage
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer />
      </div>
    );
  }
}
