import React, { Component } from "react";

import { Link } from "react-router-dom";
//apicall
import { apiCall, METHOD } from "../../constants/baseUrl";
import { CMS_PAGES } from "../../constants/api";
import { HashLink } from "react-router-hash-link";

class MiddleFooter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tandC: "",
      privarcyPolicy: "",
      aboutUs: "",
      supportEmail: "",
      instagram: "",
      faceBook: "",
      twitter: "",
    };
  }

  componentDidMount() {
    apiCall(
      CMS_PAGES,
      {},
      (data) => {
        this.setState({
          tandC: data.terms_and_conditions,
          privarcyPolicy: data.privacy_policy,
          aboutUs: data.about_us,
          supportEmail: data.support_email,
          instagram: data.instagram_url,
          faceBook: data.facebook_url,
          twitter: data.twitter_url,
        });
      },
      (error) => console.log(error),
      METHOD.GET
    );
  }

  render() {
    const {
      tandC,
      privarcyPolicy,
      aboutUs,
      instagram,
      faceBook,
      twitter,
    } = this.state;

    return (
      <footer className="footer_new">
        <div className="footer-top">
          <div className="container">
            <div className="row">
              <div className="col-md-4">
                <div className="footer-img-container">
                  <div className="footer-logo mb-4">
                    <img
                      alt="Leafgas_Image"
                      src="/assets/img/logo.svg"
                      height="45px"
                    />
                  </div>
                  <div className="store-icon">
                    <div className="mb-2">
                      <a
                        rel="noopener noreferrer"
                        target="_blank"
                        href="https://apps.apple.com/us/app/id1532588555"
                      >
                        <img
                          alt="Leafgas_AppStore"
                          src="/assets/img/new/app-store.png"
                        />
                      </a>
                    </div>
                    <div className="mb-2">
                      <a
                        rel="noopener noreferrer"
                        target="_blank"
                        href="https://play.google.com/store/apps/details?id=com.leafgasng.customer"
                      >
                        <img
                          alt="Leafgas_PlayStore"
                          src="/assets/img/new/play-store.png"
                        />
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-4">
                <h6 className="mb-3 mt-3 mt-md-0">Cylinder gas</h6>
                <ul className="list-unstyled m-0">
                  <li>
                    <Link to="/prices">Prices</Link>
                  </li>
                  <li>
                    <Link to="/nearest-dealer">Nearest Dealer</Link>
                  </li>
                  <li>
                    <Link to="/products">Products</Link>
                  </li>
                  <li>
                    <Link to="/cylinder-gas-information">
                      Cylinder Gas information
                    </Link>
                  </li>
                  {/* <!-- <li><a href="javascript:;">Promotions</a></li> --> */}
                  <li>
                    <Link to="/user-app">Mobile App</Link>
                  </li>
                </ul>
              </div>
              <div className="col-md-4">
                <h6 className="mb-3 mt-3 mt-md-0">Social Media</h6>
                <ul className="list-unstyled form-row mb-0">
                  <li className="col-sm-6">
                    <a
                      rel="noopener noreferrer"
                      target="_blank"
                      href={faceBook}
                    >
                      <img
                        alt="Leafgas Facebook"
                        className="mr-2"
                        src="/assets/img/new/facebook.png"
                      />
                      &nbsp;Facebook
                    </a>
                  </li>
                  <li className="col-sm-6">
                    <a
                      rel="noopener noreferrer"
                      href={instagram}
                      target="_blank"
                    >
                      <img
                        alt="Leafgas Instagram"
                        className="mr-2"
                        src="/assets/img/new/instagram.png"
                      />
                      &nbsp;Instagram
                    </a>
                  </li>
                  <li className="col-sm-6">
                    <a rel="noopener noreferrer" href={twitter} target="_blank">
                      <img
                        alt="Leafgas Twitter"
                        className="mr-2"
                        src="/assets/img/new/twitter.png"
                      />
                      &nbsp;Twitter
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="copyrights">
          <div className="container">
            <div className="form-row">
              <div className="col-md-6 text-center text-md-left">
                <p className="mb-2 mb-md-0">
                  Copyright © 2020, All rights Reserved. |{" "}
                  <a rel="noopener noreferrer" target="_blank" href={aboutUs}>
                    About Us
                  </a>
                </p>
              </div>
              <div className="col-md-6 text-center text-md-right">
                <a href={tandC}>Terms & Conditions</a> |&nbsp;
                <a href={privarcyPolicy}>Privacy Policy</a> |&nbsp;
                <Link to="/faqs">FAQs</Link> |&nbsp;
                <HashLink
                  to="/contact-us"
                  scroll={(el) => el.scrollIntoView({ behavior: "smooth" })}
                >
                  {" "}
                  Contact Us
                </HashLink>
              </div>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

export default MiddleFooter;
