import React, { Component } from "react";
import { Link } from "react-router-dom";
import { SocialIcon } from "react-social-icons";
//apicall
import { apiCall, METHOD } from "../../constants/baseUrl";
import { CMS_PAGES, FAQS } from "../../constants/api";
import { HashLink } from "react-router-hash-link";

class BeforeLoginFooter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      tandC: "",
      privarcyPolicy: "",
      aboutUs: "",
      supportEmail: "",
      instagram: "",
      faceBook: "",
      twitter: "",
    };
  }

  componentDidMount() {
    apiCall(
      CMS_PAGES,
      {},
      (data) => {
        this.setState({
          tandC: data.terms_and_conditions,
          privarcyPolicy: data.privacy_policy,
          aboutUs: data.about_us,
          supportEmail: data.support_email,
          instagram: data.instagram_url,
          faceBook: data.facebook_url,
          twitter: data.twitter_url,
        });
      },
      (error) => console.log(error),
      METHOD.GET
    );
  }

  // faqHandler
  // faqsListHandler = ()=>{
  //     apiCall(
  //         FAQS,
  //         {},
  //         (data)=>{

  //         },
  //         (err)=> console.log(err),
  //             METHOD.GET
  //     )
  // }
  render() {
    const {
      tandC,
      privarcyPolicy,
      aboutUs,
      supportEmail,
      instagram,
      faceBook,
      twitter,
      faqs,
    } = this.state;
    return (
      <footer className="footer_new footer_main">
        <div className="footer-top">
          <div className="container">
            <div className="row">
              <div className="col-md-4">
                <div className="footer-logo">
                  <img
                    src="/assets/img/logo-white.svg"
                    className="mb-3 mb-md-4"
                  />
                </div>
                <p className="m-0">
                  Sitting proudly atop M3565 is the two-storey penthouse. The
                  master bedroom suite is phenomenally spacious and opens out to
                  a breathtaking plunge pool.
                </p>
              </div>
              <div className="col-md-3 offset-md-1">
                <ul className="list-unstyled mt-3 mt-md-0">
                  <li className="mb-3">
                    <a href={tandC}>Terms & Conditions</a>
                  </li>
                  <li className="mb-3">
                    <a href={privarcyPolicy}>Privacy Policy</a>
                  </li>
                  <li className="mb-3">
                    <Link to="/faqsforguest">Faqs</Link>
                  </li>
                  <li>
                    <HashLink
                      to="#contact-us"
                      scroll={(el) => el.scrollIntoView({ behavior: "smooth" })}
                    >
                      {" "}
                      Contact Us
                    </HashLink>
                  </li>
                </ul>
              </div>
              <div className="col-md-4">
                <h6 className="mb-3 mt-2 mt-md-0 text-white font-weight-normal">
                  Leaf Gas Company
                </h6>
                <div className="address-fields">
                  <p className="mb-2">
                    House no. 121 Westlands, near new building, Abuja Nigeria
                    email@leafgas.com
                  </p>
                  <p className="mb-2">
                    <a href="tel:+234-123-4567">+234 123-4567</a>
                  </p>
                  <p className="mb-2">
                    <a href={`mailto:${supportEmail}`}>{supportEmail}</a>
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="copyrights">
          <div className="container">
            <div className="form-row">
              <div className="col-md-6 text-center text-md-left">
                <p className="mb-2 mb-md-0">
                  Copyright © 2020, All rights Reserved.
                </p>
              </div>
              <div className="col-md-6 text-center text-md-right">
                <ul className="list-inline social-icons m-0">
                  <li className="list-inline-item">
                    <a href={faceBook}>
                      <i className="zmdi zmdi-facebook zmdi-hc-fw"></i>
                    </a>
                  </li>
                  <li className="list-inline-item">
                    <a href={twitter} target="_blank">
                      <i className="zmdi zmdi-twitter zmdi-hc-fw"></i>
                    </a>
                  </li>
                  <li className="list-inline-item">
                    <a href={instagram} target="_blank">
                      <i className="zmdi zmdi-instagram zmdi-hc-fw"></i>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </footer>
    );
  }
}

export default BeforeLoginFooter;
