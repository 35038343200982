import {
  ADD_TO_CART,
  REMOVE_FROM_CART,
  CLEAR_CART,
  SHOW_TO_CART,
  UPDATE_TO_CART,
} from "../constants/action-types";

export const showToCart = (payload) => {
  return {
    type: SHOW_TO_CART,
    payload,
  };
};

export const updateToCart = (payload) => {
  return {
    type: UPDATE_TO_CART,
    payload,
  };
};

export const addToCart = (payload) => {
  return {
    type: ADD_TO_CART,
    payload,
  };
};

export const removeFromCart = (payload) => {
  return {
    type: REMOVE_FROM_CART,
    payload,
  };
};
export const clearCart = (payload) => ({
  type: CLEAR_CART,
  payload,
});
