import { COMMENT_FEED, DELETE_FEED, GET_FEED_LIST, LIKE_FEED } from "../constants/action-types";
import { objectProcessor } from "../util/utils";

const initialState = {};

const likeToLocal = (state, key) => {
  const list = state.feed_list.map(feed => {
    if (feed.id === key && feed.is_liked !== 1) {
      feed.is_liked = 1;
      feed.likes_count = feed.likes_count + 1;
    } else if (feed.id === key && feed.is_liked === 1) {
      feed.is_liked = 0;
      feed.likes_count = feed.likes_count - 1;
    }
    return feed;
  });

  return {
    ...state,
    feed_list: [...list]
  };
};

const commentToLocal = (state, key) => {
  const list = state.feed_list.map(feed => {
    if (feed.id === key) {
      feed.comments_count = feed.comments_count + 1;
    }
    return feed;
  });

  return {
    ...state,
    feed_list: [...list]
  };
};

const feedReducer = (state = initialState, action) => {
  //console.log("action feedReducer", action, state);
  switch (action.type) {
    case GET_FEED_LIST:
      return objectProcessor(state, action.payload);
    case LIKE_FEED:
      return likeToLocal(state, action.payload, {
        is_liked: 1
      });
    case COMMENT_FEED:
      return commentToLocal(state, action.payload, {
        is_commentd: 1
      });
    case DELETE_FEED:
      // return objectProcessor(state, { feed_list: withLiked });
      let list = state.feed_list;
      return {
        ...state,
        feed_list: list.filter(feed => {
          //console.log("test", feed.id, action.payload);
          return feed.id !== action.payload;
        })
      };
    default:
      return state;
  }
};

export default feedReducer;
