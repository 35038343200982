import {
  CLEAR_FCM_PUSH_NOTIFICATIONS,
  SHOW_FCM_PUSH_NOTIFICATIONS,
  UPDATE_FCM_PUSH_NOTIFICATIONS,
} from "../constants/action-types";

// import { notification_type } from "../constants/fcm-types";

const initialState = {
  admin: null,
  Promocode: null,
  order_status: null,
  cancel_status_user: null,
  cancel_status_rider: null,
  cancel_status_admin: null,
  payment_status: null,
  no_rider_found: null,
  deposit_refund: null,
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case SHOW_FCM_PUSH_NOTIFICATIONS:
      return { ...state, ...payload };

    case UPDATE_FCM_PUSH_NOTIFICATIONS:
      return { ...state, ...payload };

    case CLEAR_FCM_PUSH_NOTIFICATIONS:
      return {
        admin: null,
        Promocode: null,
        order_status: null,
        cancel_status_user: null,
        cancel_status_rider: null,
        cancel_status_admin: null,
        payment_status: null,
        no_rider_found: null,
        deposit_refund: null,
      };
    default:
      return state;
  }
};
