import React, { Component } from "react";
import { Link, Redirect } from "react-router-dom";
import { connect } from "react-redux";
import * as actions from "../../constants/mapDispatchToProps";
import * as stateToProps from "../../constants/mapStateToProps";
import ExampleComponent from "react-rounded-image";
import image from "./Img/private.png";
import { apiCall, METHOD } from "../../constants/baseUrl";
import { USER_LOGOUT } from "../../constants/api";
import { getHeaderToken } from "../../util/utils";
import { messaging } from "../../util/init-fcm";

class AfterLoginHeader extends Component {
  constructor(props) {
    super();
    this.state = {
      log: false,
      useravtar: "",
      cartData: 0,
    };
  }

  clearDataAfterLogout = () => {
    localStorage.removeItem("isLoggedIn");
    localStorage.removeItem("path");
    localStorage.removeItem("user_info");
    localStorage.removeItem("userInfo");
    localStorage.removeItem("user_address");
    localStorage.removeItem("rider_directions");
    localStorage.removeItem("applied_promocode");
    localStorage.removeItem("order_id");
    localStorage.removeItem("address_id");
    localStorage.removeItem("payment_mode");
    localStorage.removeItem("token");
    localStorage.removeItem("type");
    const oldToken = localStorage.getItem("FCM_TOKEN");
    localStorage.removeItem("FCM_TOKEN");
    // messaging
    //   .deleteToken(oldToken)
    //   .then((isDeleted) => {
    //     console.log("FCM Token Refreshed");
    //   })
    //   .catch(() => {
    //     console.log("FCM Token : Catch block logged");
    //   })
    //   .finally(() => {
    //   });
    this.generateNewFCMToken();
    sessionStorage.clear();
    this.props.clearAddress();
    this.setState({ log: true });
  };

  generateNewFCMToken = async () => {
    const token = await messaging.getToken();
    if (process.env.NODE_ENV === "development") {
      console.log("FCM Token", token);
    }
    localStorage.setItem("FCM_TOKEN", token);
  };

  logout = () => {
    apiCall(
      USER_LOGOUT,
      {},
      (data) => {
        console.info(data.message);
      },
      (err) => {
        console.error(err);
      },
      METHOD.POST,
      getHeaderToken()
    );
    this.clearDataAfterLogout();
  };

  componentDidMount() {
    window.scrollTo(0, 0);
    const data = localStorage.getItem("user_info");
    const cart = localStorage.getItem("cart_info");

    if (data || cart) {
      const data1 = JSON.parse(data);
      const data2 = data1.user_info;
      const cart1 = JSON.parse(cart);
      const cartFin = cart1 || 0;

      this.setState({
        useravtar: data2?.profile_image,
        cartData: cartFin,
      });
    }
  }

  componentDidUpdate(previousProps, previousState) {
    // const hasCartItem = localStorage.getItem("user_info");
    if (previousProps.photoSend !== this.props.photoSend) {
      if (this.props.photoSend) {
        this.setState({
          useravtar: this.props.photoSend,
        });
      }
    } else if (previousProps.CartItem !== this.props.CartItem) {
      if (this.props.CartItem >= 0) {
        this.setState({
          cartData: this.props.CartItem,
        });
      }
    }
  }

  // componentDidUpdate(previousProps, previousState) {
  //   const hasCartItem = localStorage.getItem('user_info')
  //   if (previousProps.CartItem != this.props.CartItem || hasCartItem) {
  //     if (hasCartItem) {
  //       this.setState({
  //         cartData: hasCartItem,
  //       });
  //       }

  //   }
  // }

  // coming Soon handler
  // closeModal=()=>{
  //   this.setState({
  //     isSecurity: false,
  //   })

  // }
  // showModal= ()=>{
  //   this.setState({
  //     isSecurity:true
  //   })

  // }

  render() {
    const {
      price,
      nearestDealer,
      products,
      cylinderGas,
      mobileApp,
    } = this.props;

    if (this.state.log) {
      return <Redirect to="/sign-in" />;
    }
    return (
      <nav className="navbar navbar-expand-lg navbar-light">
        <div className="container">
          <div className="d-flex align-items-center mr-auto">
            <Link className="navbar-brand" to="/">
              <img
                src="/assets/img/logo.svg"
                className="d-none d-md-inline"
                height="45px"
                alt="logo"
              />
              <div className="logo-icon d-inline-block d-md-none">
                <img src="/assets/img/logo-icon.png" alt="logo" />
              </div>
            </Link>
          </div>
          <div className="right order-1 order-lg-2">
            <ul className="navbar-nav align-items-center flex-row">
              <li className="nav-item">
                {/* to="my-cart" */}
                <Link className="nav-link" to="/my-cart">
                  <i className="zmdi zmdi-shopping-cart zmdi-hc-lg"></i>{" "}
                  <span className="cart-text">({this.state.cartData})</span>
                </Link>
              </li>

              <li className="nav-item dropdown user-dropdown">
                <Link
                  to="/"
                  className="nav-link dropdown-toggle user-avatar"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  <ExampleComponent
                    image={!this.state.useravtar ? image : this.state.useravtar}
                    roundedSize="0"
                    imageWidth="35"
                    imageHeight="35"
                  />
                  &nbsp;
                  {/* <img src="/assets/img/user.jpg" className="rounded-circle" alt="User Name" /> */}
                </Link>
                <div className="dropdown-menu dropdown-menu-right m-0">
                  <div className="log-arrow-up"></div>
                  {/* to="/my-order" */}
                  <Link className="dropdown-item" to="/my-order">
                    My Orders
                  </Link>
                  {/* to="/delivery-address" */}
                  <Link className="dropdown-item" to="/delivery-address">
                    Delivery Address
                  </Link>
                  {/* to="/notifications" */}
                  <Link className="dropdown-item" to="/notifications">
                    Notifications
                  </Link>
                  {/* to="/security-deposit" */}
                  <Link className="dropdown-item" to="/security-deposit">
                    Security Deposit
                  </Link>
                  {/* to="/bank-info" */}
                  <Link className="dropdown-item" to="/bank-info">
                    Bank Info
                  </Link>
                  {/* to="/settings" */}
                  <Link className="dropdown-item" to="/settings">
                    Settings
                  </Link>
                  <Link
                    className="dropdown-item text-danger"
                    onClick={this.logout}
                    to="/sign-in"
                  >
                    Sign Out
                  </Link>
                </div>
              </li>
            </ul>
          </div>
          <div
            className="navbar-collapse collapse order-3 order-lg-1 justify-content-end"
            id="navbarSupportedContent"
          >
            <ul className="navbar-nav">
              <li className={`nave-utem ${price ? "active" : ""}`}>
                <Link className="nav-link" to="/prices">
                  Prices
                </Link>
              </li>
              <li className={`nave-utem ${nearestDealer ? "active" : ""}`}>
                <Link className="nav-link" to="/nearest-dealer">
                  Nearest Dealer
                </Link>
              </li>
              <li className={`nave-utem ${products ? "active" : ""}`}>
                <Link className="nav-link" to="/products">
                  Products
                </Link>
              </li>
              <li className={`nave-utem ${cylinderGas ? "active" : ""}`}>
                <Link className="nav-link" to="/cylinder-gas-information">
                  Cylinder gas information
                </Link>
              </li>
              <li className={`nave-utem ${mobileApp ? "active" : ""}`}>
                <Link className="nav-link" to="/user-app">
                  Mobile App
                </Link>
              </li>
            </ul>
          </div>
          <button
            className="navbar-toggler order-2"
            type="button"
            data-toggle="collapse"
            data-target="#navbarSupportedContent"
            aria-controls="navbarSupportedContent"
            aria-expanded="false"
            aria-label="Toggle navigation"
          >
            <img src="/assets/img/menu.png" alt="menu img" />
          </button>
        </div>
      </nav>
    );
  }
}

export default connect(
  (state) =>
    stateToProps.appMapStateToProps(
      [
        stateToProps.asyncApiStateToProps,
        stateToProps.authStateToProps,
        stateToProps.clearAddressStateToProps,
      ],
      state
    ),
  actions.appMapDispatchToProps([
    actions.asyncApiDispatchToProps,
    actions.clearAddressDispatchToProps,
    //Linkctions.authDispatchToProps,
  ])
)(AfterLoginHeader);
