import {
  addToken,
  checkUser,
  asyncApi,
  signupRequest,
  deleteApiState,
  loginRequest,
  addToCart,
  showToCart,
  removeFromCart,
  updateToCart,
  clearCart,
  deletedToken,
} from "../action";
import { addCylinderData } from "../action/singleCylinderActions";
import {
  clearNotification,
  showNotification,
} from "../action/NotificationAction";
import {
  showAddress,
  deleteAddress,
  addAddress,
  editAddress,
  clearAddress,
} from "../action/addAddress";

import {
  showHistoryAddress,
  addHistoryAddress,
} from "../action/historyAddressAction";

import {
  showPreviewList,
  updatePreviewListItem,
  removePreviewListItem,
} from "../action/PreviewAction";

import {
  clearFCMPushNotificationsAction,
  showFCMPushNotificationsAction,
  updateFCMPushNotificationsAction,
} from "../action/fcmPushNotifications";

export const asyncApiDispatchToProps = (dispatch) => {
  return {
    apiCall: (
      endPoint,
      params,
      successMethod,
      errorMethod,
      method = "post",
      config
    ) => {
      dispatch(
        asyncApi(endPoint, params, successMethod, errorMethod, method, config)
      );
    },
    deleteApiState: () => dispatch(deleteApiState()),
    loginRequest: (data) => dispatch(loginRequest(data)),
    deletedToken: () => dispatch(deletedToken()),
  };
};

export const appMapDispatchToProps = (data) => {
  // debugger
  let tempObj = {};
  return (dispatch) => {
    data.map((d) => {
      const m = d(dispatch);
      Object.keys(m).map((key) => (tempObj[key] = m[key]));
      //console.log(m, Object.keys(m));
      //console.log(tempObj);
    });
    return tempObj;
  };
};

// export const associationDispatchToProps = dispatch => {
//   return {
//     getAssociationDetail: data => dispatch(getAssociationDetail(data))
//   };
// };

export const authDispatchToProps = (dispatch) => {
  return {
    addToken: (article) => dispatch(addToken(article)),
    checkUser: () => dispatch(checkUser()),
    // deleteAuthState: () => dispatch(deleteAuthState()),
    signupRequest: (data) => dispatch(signupRequest(data)),
    // deletedToken:()=>dispatch(deletedToken())
    // deleteArticle: index => dispatch(deleteArticle(index))
  };
};

export const singleCylinderDispatchToProps = (dispatch) => {
  return {
    addCylinderData: (article) => dispatch(addCylinderData(article)),
  };
};
export const cartDispatchToProps = (dispatch) => {
  return {
    addToCart: (article) => dispatch(addToCart(article)),
  };
};
export const showCartDispatchToProps = (dispatch) => {
  return {
    showToCart: (article) => dispatch(showToCart(article)),
  };
};

export const removeCartDispatchToProps = (dispatch) => {
  // console.log("removeCartDispatchToProps  calling")
  return {
    removeFromCart: (article) => dispatch(removeFromCart(article)),
  };
};

export const updateCartDispatchToProps = (dispatch) => {
  // console.log("updateCartDispatchToProps  calling");
  return {
    updateToCart: (article) => dispatch(updateToCart(article)),
  };
};

export const clearCartDispatchToProps = (dispatch) => {
  // console.log("clearCartDispatchToProps Calling");
  return {
    clearCart: (article) => dispatch(clearCart(article)),
  };
};

export const previewListDispatchToProps = (dispatch) => {
  //console.log("updateCartDispatchToProps  calling")
  return {
    showPreviewList: (article) => dispatch(showPreviewList(article)),
  };
};

export const updatepreviewListDispatchToProps = (dispatch) => {
  // console.log("updateCartDispatchToProps  calling");
  return {
    updatePreviewListItem: (article) =>
      dispatch(updatePreviewListItem(article)),
  };
};

export const removepreviewListDispatchToProps = (dispatch) => {
  // console.log("updateCartDispatchToProps  calling");
  return {
    removePreviewListItem: (article) =>
      dispatch(removePreviewListItem(article)),
  };
};

// export const EditProfileDispatchToProps = (dispatch) => {
//   return {
//     editProfile: (article) => dispatch(editProfile(article)),
//   };
// };
// export const changePasswordDispatchToProps = (dispatch) => {
//   return {
//     changePassword: (article) => dispatch(changePassword(article)),
//   };
// };

export const clearNotificationDispatchToProps = (dispatch) => {
  return {
    clearNotification: (article) => dispatch(clearNotification(article)),
  };
};

export const showNotificationDispatchToProps = (dispatch) => {
  return {
    showNotification: (article) => dispatch(showNotification(article)),
  };
};

export const readNotificationDispatchToProps = (dispatch) => {
  return {
    readNotification: (article) => dispatch(showNotification(article)),
  };
};

export const showAddressDispatchToProps = (dispatch) => {
  return {
    showAddress: (article) => dispatch(showAddress(article)),
  };
};

export const deleteAddressDispatchToProps = (dispatch) => {
  return {
    deleteAddress: (article) => dispatch(deleteAddress(article)),
  };
};

export const addAddressDispatchToProps = (dispatch) => {
  return {
    addAddress: (article) => dispatch(addAddress(article)),
  };
};
export const editAddressDispatchToProps = (dispatch) => {
  return {
    editAddress: (article) => dispatch(editAddress(article)),
  };
};

export const clearAddressDispatchToProps = (dispatch) => {
  return {
    clearAddress: (article) => dispatch(clearAddress(article)),
  };
};

export const addHistoryAddressDispatchToProps = (dispatch) => {
  return {
    addHistoryAddress: (article) => dispatch(addHistoryAddress(article)),
  };
};
export const showHistoryAddressDispatchToProps = (dispatch) => {
  return {
    showHistoryAddress: (article) => dispatch(showHistoryAddress(article)),
  };
};

export const showFCMPushNotificationsDispatchToProps = (dispatch) => {
  return {
    showFCMPushNotifications: (article) =>
      dispatch(showFCMPushNotificationsAction(article)),
  };
};

export const updateFCMPushNotificationsDispatchToProps = (dispatch) => {
  return {
    updateFCMPushNotifications: (article) =>
      dispatch(updateFCMPushNotificationsAction(article)),
  };
};

export const clearFCMPushNotificationsDispatchToProps = (dispatch) => {
  return {
    clearFCMPushNotifications: (article) =>
      dispatch(clearFCMPushNotificationsAction(article)),
  };
};
