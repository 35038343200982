import React, { Component } from "react";
import { Link } from "react-router-dom";

class HeaderGuest extends Component {
  render() {
    const {
      price,
      nearestDealer,
      products,
      cylinderGas,
      mobileApp,
      signInSignUp,
    } = this.props;
    return (
      <React.Fragment>
        <nav className="navbar navbar-expand-lg navbar-light">
          <div className="container">
            <div className="d-flex align-items-center mr-auto">
              <Link className="navbar-brand" to="/">
                {/* <img src="/assets/img/logo.svg" height="45px" /> */}
                <img
                  src="/assets/img/logo.svg"
                  className="d-none d-md-inline"
                  height="45px"
                />
                <div className="logo-icon d-inline-block d-md-none">
                  <img src="/assets/img/logo-icon.png" />
                </div>
              </Link>
            </div>
            <div className="right order-2 order-lg-4">
              <ul className="navbar-nav align-items-center flex-row">
                <li className="nav-item">
                  <Link className="nav-link" to="/my-cart">
                    <i className="zmdi zmdi-shopping-cart zmdi-hc-lg"></i>{" "}
                    <span className="d-none d-lg-inline"> Cart </span>{" "}
                    <span className="cart-text">(0)</span>
                  </Link>
                </li>
                <li className={`nav-item ${signInSignUp ? "active" : ""}`}>
                  <Link className="nav-link" to="/sign-in">
                    Login/Sign Up{" "}
                  </Link>
                </li>
              </ul>
            </div>
            <div
              className="navbar-collapse order-4 order-lg-3 collapse justify-content-end"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav">
                <li className={`nav-item ${price ? "active" : ""}`}>
                  <Link className="nav-link" to="/">
                    Prices
                  </Link>
                </li>
                <li className={`nav-item ${nearestDealer ? "active" : ""}`}>
                  <Link className="nav-link" to="/nearest-dealer">
                    Nearest Dealer
                  </Link>
                </li>
                <li className={`nav-item ${products ? "active" : ""}`}>
                  <Link className="nav-link" to="/products">
                    Products
                  </Link>
                </li>
                <li className={`nav-item ${cylinderGas ? "active" : ""}`}>
                  <Link className="nav-link" to="/cylinder-gas-information">
                    Cylinder gas information
                  </Link>
                </li>
                <li className={`nav-item ${mobileApp ? "active" : ""}`}>
                  <Link className="nav-link" to="/user-app">
                    Application
                  </Link>
                </li>
              </ul>
            </div>
            <button
              className="navbar-toggler order-2"
              type="button"
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <img src="assets/img/menu.png" alt="menu img" />
            </button>
          </div>
        </nav>
      </React.Fragment>
    );
  }
}
export default HeaderGuest;
