import {
  CLEAR_NOTIFICATION,
  SHOW_NOTIFICATION,
} from "../constants/action-types";
import { READ_NOTIFICATION } from "../constants/api";

const initialState = {
  details: [],
};

export default (state = initialState, { type, payload }) => {
  
  switch (type) {
    case SHOW_NOTIFICATION:
      return { ...state, details : payload };
    case CLEAR_NOTIFICATION:
      return {
        ...state,
        details: [],
      };
      case READ_NOTIFICATION:
      return{
         ...state, deatails:payload
      }
    default:
      return state;
  }
};
