import {
  SHOW_PREVIEW_LIST,
  ADD_PREVIEW_LIST,
  DELETE_PREVIEW_LIST,
  UPDATE_PREVIEW_LIST,
} from "../constants/action-types";

export const showPreviewList = (payload) => {
  return {
    type: SHOW_PREVIEW_LIST,
    payload,
  };
};

export const removePreviewListItem = (payload) => {
  return {
    type: DELETE_PREVIEW_LIST,
    payload,
  };
};

export const addPreviewListItem = (payload) => {
  return {
    type: ADD_PREVIEW_LIST,
    payload,
  };
};
export const updatePreviewListItem = (payload) => {
  return {
    type: UPDATE_PREVIEW_LIST,
    payload,
  };
};
