import {
  CLEAR_FCM_PUSH_NOTIFICATIONS,
  SHOW_FCM_PUSH_NOTIFICATIONS,
  UPDATE_FCM_PUSH_NOTIFICATIONS,
} from "../constants/action-types";

export const updateFCMPushNotificationsAction = (payload) => {
  return {
    type: UPDATE_FCM_PUSH_NOTIFICATIONS,
    payload,
  };
};

export const showFCMPushNotificationsAction = (payload) => {
  return {
    type: SHOW_FCM_PUSH_NOTIFICATIONS,
    payload,
  };
};

export const clearFCMPushNotificationsAction = (payload) => {
  return {
    type: CLEAR_FCM_PUSH_NOTIFICATIONS,
    payload,
  };
};
