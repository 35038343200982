


// // if (process.env.NODE_ENV === "production") {
// //   config.baseURL = "https://admin.leafgas.com/web/v1";
// // }

// export const METHOD = {
//   POST: "post",
//   GET: "get",
// };

// export const apiCall = (
//   endpoint,
//   bodyFormData = new FormData(),
//   onSuccess,
//   onFailure,
//   method = METHOD.POST,
//   DyanamicConfig = {}
// ) => {
//   console.log("endpoint", DyanamicConfig.headers );
//   const fcmToken = localStorage.getItem("FCM_TOKEN");
//   bodyFormData instanceof FormData && bodyFormData.set("device_id", fcmToken);
//   bodyFormData instanceof FormData && bodyFormData.set("device_type", "A");
//   let request = {};
//   let token = getSaveToken();
//   const configgs  = {
//     Authorization: token,
//   }
//   const headers = DyanamicConfig.headers === undefined ? {} : configgs
//   switch (method) {
//     case METHOD.POST:
//       request = axios({
//         // method: "post",
//         url: config.baseURL + endpoint,
//         data: bodyFormData,
//         // ...headers,
//         headers,
//       });
//       break;
//     case METHOD.GET:
//       request = axios({
//         method: "get",
//         // headers,
//         url: config.baseURL + endpoint,
//         headers
//         // ...DyanamicConfig,
//       });
//       break;
//       default: break
//   }
//   request
//     .then((response) => {
//       if (process.env.NODE_ENV === "development") {
//         console.log("sucess data :", response.data.data);
//       }
//       var error = response.error;
//       if (response) {
//         if (process.env.NODE_ENV === "development") {
//           console.log(response.status, "response");
//         }
//         if (response.data.success === 1) {
//           if (response.status === 200) {
//             try {
//               onSuccess(
//                 response.data.data ? response.data.data : response.data
//               );
//               //onSuccess(response)
//             } catch (err) {
//               onFailure("Something went wrong");
//             }
//           } else if (response.status === 401) {
//             onFailure("Session expired");
//           } else {
//             onFailure(
//               error && typeof error === "string"
//                 ? error
//                 : "Something went wrong"
//             );
//           }
//         } else {
//           let error = response.data.error;
//           error = Array.isArray(error) ? error[0] : error;
//           onFailure(error ? error : "Something went wrong");
//         }
//       } else {
//         onFailure("Something went wrongahajdh");
//       }
//     })
//     .catch((error) => {
//       if (process.env.NODE_ENV === "development") {
//         console.log(error, "error");
//       }

//       if (error && error.response) {
//         switch (error.response.status) {
//           case 401:
//             onFailure("Session expired");
//             break;
//           default:
//             onFailure(
//               error && typeof error === "string"
//                 ? error
//                 : "Something went wrong"
//             );
//             break;
//         }
//       } else onFailure("Something went wrong");
//     });
// };
import axios from "axios";
import { getSaveToken } from "../util/utils";
import { SIGNIN } from "./api";
export const METHOD = {
  POST: 'post',
  GET: 'get',
  PUT: 'put',
  DELETE: 'delete',
  PATCH: 'patch'
};


export const apiCall = (
  endpoint,
  bodyFormData = new FormData(),
  onSuccess,
  onFailure,
  method = METHOD.POST,
  DynamicConfig = {}
) => {

  const config = {
    // baseURL: "http://13.234.81.103/apps/leafgas/web/v1", // Dev URL
    baseURL: "https://admin.leafgas.com/web/v2", // LIVE
    // baseURL: "https://4bc1-180-211-110-125.ngrok-free.app/apps/leafGas/web/v2",
    // baseURL: "http://192.168.1.106/apps/leafGas/web/v2", 
    // baseURL: "http://13.234.218.36:5012/v2",
  };

  const api = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
  });
  const fcmToken = localStorage.getItem("FCM_TOKEN");
  bodyFormData instanceof FormData && bodyFormData.set("device_id", fcmToken);
  bodyFormData instanceof FormData && bodyFormData.set("device_type", "A");
  console.log("data we arew [pass, v", DynamicConfig);

  console.log("bhjgjgh", endpoint)
  const request = () => {
    switch (method) {
      case METHOD.POST:
        return api.post(endpoint, bodyFormData, DynamicConfig);
      case METHOD.GET:
        return api.get(endpoint, DynamicConfig);
      case METHOD.DELETE:
        return api.delete(endpoint, DynamicConfig);
      case METHOD.PUT:
        return api.put(endpoint, bodyFormData, DynamicConfig);
      case METHOD.PATCH:
        return api.patch(endpoint, bodyFormData, DynamicConfig);
    }
    return new Promise((resolve, reject) => resolve({}));
  };

  if(endpoint === SIGNIN){
    console.log("login", DynamicConfig)
  } 
  const req = request();
  req
    .then((response) => {
      if (process.env.NODE_ENV === "development") {
        console.log("sucess data :", response.data.data);
      }
      var error = response.error;
      if (response) {
        if (process.env.NODE_ENV === "development") {
          console.log(response.status, "response");
        }
        if (response.data.success === 1) {
          if (response.status === 200) {
            try {
              onSuccess(
                response.data.data ? response.data.data : response.data
              );
              //onSuccess(response)
            } catch (err) {
              onFailure("Something went wrong");
            }
          } else if (response.status === 401) {
            onFailure("Session expired");
          } else {
            onFailure(
              error && typeof error === "string"
                ? error
                : "Something went wrong"
            );
          }
        } else {
          let error = response.data.error;
          error = Array.isArray(error) ? error[0] : error;
          onFailure(error ? error : "Something went wrong");
        }
      } else {
        onFailure("Something went wrongahajdh");
      }
    })
    .catch((error) => {
      if (process.env.NODE_ENV === "development") {
        console.log(error, "error");
      }

      if (error && error.response) {
        switch (error.response.status) {
          case 401:
            onFailure("Session expired");
            break;
          default:
            onFailure(
              error && typeof error === "string"
                ? error
                : "Something went wrong"
            );
            break;
        }
      } else onFailure("Something went wrong");
    });

};
