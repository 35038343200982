// TOKEN ACTIONS
export const ADD_TOKEN = "ADD_TOKEN";
export const DELETE_TOKEN = "DELETE_TOKEN";
export const GET_INITIAL_TOKEN = "GET_INITIAL_TOKEN";

// API CALLS ACTIONS
export const SUCCESS = "SUCCESS";
export const FAILURE = "FAILURE";
export const STARTED = "STARTED";
export const DELETE = "DELETE";

//AUTH ACTIONS
export const LOGIN_REQUEEST = "LOGIN_REQUEEST";
export const SIGNUP_REQUEST = "SIGNUP_REQUEST";
export const IS_USER_LOGGEDIN = "IS_USER_LOGGEDIN";
export const IS_USER_VERIFIED = "IS_USER_VERIFIED";

export const ADD_ASSOCIATION_DETAILS = "ADD_ASSOCIATION_DETAILS";
export const CREATE_ASSOCIATION_SECTIONS = "CREATE_ASSOCIATION_SECTIONS";
export const ADD_ASSOCIATION_SECTION_DETAIL = "ADD_ASSOCIATION_SECTION_DETAIL";

export const DELETE_ASSOCIATION_SECTION = "DELETE_ASSOCIATION_SECTION";
export const CREATE_ASSOCIATION_DETAIL = "CREATE_ASSOCIATION_DETAIL";
export const DRAG_SECTION = "DRAG_SECTION";

export const ASSOCIATION_CATEGORY_LIST = "ASSOCIATION_CATEGORY_LIST";

export const GET_FEED_LIST = "GET_FEED_LIST";
export const ADD_PROJECT_DETAILS = "ADD_PROJECT_DETAILS";
export const LIKE_FEED = "LIKE_FEED";
export const DELETE_FEED = "DELETE_FEED";
export const COMMENT_FEED = "COMMENT_FEED";

export const ADD_IMAGES = "ADD_IMAGES";
export const REMOVE_IMAGE = "REMOVE_IMAGE";
export const REMOVE_ALL_IMAGE = "REMOVE_ALL_IMAGE";
export const ASSOCIATION_LIST = "ASSOCIATION_LIST";

export const GET_BANK_DETAIL = "GET_BANK_DETAIL";
export const GET_PROFILE = "GET_PROFILE";
export const COMPLETE_PROFILE = "COMPLETE_PROFILE";
export const ADD_BANK_DETAIL = "ADD_BANK_DETAIL";
export const DOCUMENT_LIST = "DOCUMENT_LIST";

export const ADD_CYLINDER_DATA = "ADD_CYLINDER_DATA";

export const ADD_TO_CART = "ADD_TO_CART";
export const REMOVE_FROM_CART = "REMOVE_FROM_CART";
export const CLEAR_CART = "CLEAR_CART";
export const SHOW_TO_CART = "SHOW_TO_CART";
export const UPDATE_TO_CART = "UPDATE_TO_CART";

export const EDIT_PROFILE = "EDIT_PROFILE";
export const CHANGE_PASSWORD = "CHANGE_PASSWORD";

export const CLEAR_NOTIFICATION = "CLEAR_NOTIFICATION";
export const SHOW_NOTIFICATION = "SHOW_NOTIFICATION";
export const READ_NOTIFICATION = "READ_NOTIFICATION";

export const SHOW_ADDRESS = "SHOW_ADDRESS";
export const DELETE_ADDRESS = "DELETE_ADDRESS";
export const ADD_ADDRESS = "ADD_ADDRESS";
export const EDIT_ADDRESS = "EDIT_ADDRESS";
export const CLEAR_ADDRESS = "CLEAR_ADDRESS";

export const ADD_HISTORY_ADDRESS = "ADD_HISTORY_ADDRESS";
export const SHOW_HISTORY_ADDRESS = "SHOW_HISTORY_ADDRESS";

export const SHOW_PREVIEW_LIST = "SHOW_PREVIEW_LIST";
export const UPDATE_PREVIEW_LIST = "UPDATE_PREVIEW_LIST";
export const DELETE_PREVIEW_LIST = "DELETE_PREVIEW_LIST";
export const ADD_PREVIEW_LIST = "ADD_PREVIEW_LIST";

export const SHOW_FCM_PUSH_NOTIFICATIONS = "SHOW_FCM_PUSH_NOTIFICATIONS";
export const CLEAR_FCM_PUSH_NOTIFICATIONS = "CLEAR_FCM_PUSH_NOTIFICATIONS";
export const UPDATE_FCM_PUSH_NOTIFICATIONS = "UPDATE_FCM_PUSH_NOTIFICATIONS";
