import React, { Component } from "react";
import AfterLoginHeader from "./AfterLoginHeader";
import HeaderGuest from "./HeaderGuest";
import BeforeLoginHeader from "./BeforeLoginHeader";

class Index extends Component {
  componentDidMount() {
    window.scrollTo(0, 0);
  }
  render() {
    const isLoggedIn = localStorage.getItem("isLoggedIn");
    const { home } = this.props;
    if (isLoggedIn && !home) {
      return <AfterLoginHeader {...this.props} />;
    } else if (isLoggedIn && home) {
      return <AfterLoginHeader {...this.props} />;
    } else if (home) {
      return <BeforeLoginHeader />;
    } else return <HeaderGuest {...this.props} />;
  }
}
export default Index;
