import { combineReducers } from "redux";
import apiReducer from "./apiCalls";
import authReducer from "./authReducer";
import feedReducer from "./FeedReducer";
import singleCylinderReducer from "./singleCylinderReducer";
import cartReducer from "./cartReducer";
import notificationReducers from "./notificationReducer";
import addAddressReducer from "./addAddressReducers";
import historyAddressReducer from "./historyAddressReducer";
import PreviewReducer from "./PreviewReducer";
import fcmPushNotificationReducer from "./fcmPushNotificationsReducer";

// Combine all reducers as root reducer
export default combineReducers({
  authReducer,
  apiReducer,
  feedReducer,
  singleCylinderReducer,
  cartReducer,
  notificationReducers,
  addAddressReducer,
  historyAddressReducer,
  PreviewReducer,
  fcmPushNotificationReducer,
});
