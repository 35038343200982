import React, { Component } from 'react'
import AfterLoginFooter from './AfterLoginFooter'
import BeforeLoginFooter from './BeforeLoginFooter'
import MiddleFooter from './MiddleFooter'

export class index extends Component {
    render() {
        const isLoggedIn = localStorage.getItem('isLoggedIn')
        const { home} = this.props
        if(isLoggedIn){
           return <MiddleFooter/>
        }else if(home){
          return  <BeforeLoginFooter/>
        }else{
           return <AfterLoginFooter/>
        }
      
    }
}

export default index
