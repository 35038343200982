export const notification_type = {
  admin: "admin",
  Promocode: "Promocode",
  order_status: "order_status",
  cancel_status_user: "cancel_status_user",
  cancel_status_rider: "cancel_status_rider",
  cancel_status_admin: "cancel_status_admin",
  payment_status: "payment_status",
  no_rider_found: "no_rider_found",
  deposit_refund: "deposit_refund",
};
