export const asyncApiStateToProps = (state) => {
  return { apiState: state.apiReducer };
};

export const authStateToProps = (state) => {
  return { authReducer: state.authReducer };
};

export const appMapStateToProps = (data, state) => {
  let tempObj = {};
  data.map((d) => {
    let reducerObj = d(state);
    Object.keys(reducerObj).map((key) => (tempObj[key] = reducerObj[key]));
  });
  return tempObj;
};

export const singleCylinderMapStateToProps = (state) => {
  return { singleCylinderReducer: state.singleCylinderReducer };
};

export const cartMapStateToProps = (state) => {
  // console.log("cartMapStateToProps :", state);
  return { cartReducer: state.cartReducer };
};
export const showCartMapStateToProps = (state) => {
  //console.log("showCartMapStateToProps:", state)
  return { cartReducer: state.cartReducer };
};

export const removeCartMapStateToProps = (state) => {
  //console.log("cartremoveCartMapStateToPropsMapStateToProps :", state)
  return { cartReducer: state.cartReducer };
};
export const updateCartMapStateToProps = (state) => {
  //console.log("cartMapStateToProps :", state)
  return { updateCartReducer: state.updateCartReducer };
};

export const clearCartMapStateToProps = (state) => {
  return { cartReducer: state.cartReducer };
};

export const showNotificationMapStateToProps = (state) => {
  return { notificationReducer: state.notificationReducer };
};

export const clearNotificatonMapStateToProps = (state) => {
  return {
    clearNotificationReducer: state.clearNotificationReducer,
  };
};
export const readNotificationMapStateToProps = (state) => {
  return {
    readNotificationReducer: state.readNotificationReducer,
  };
};

export const showAddressMapStateToProps = (state) => {
  return {
    addAddressReducer: state.addAddressReducer,
  };
};
export const deleteAddressMapStateToProps = (state) => {
  //console.log("deleteAddressMapStateToProps:", state)
  return {
    deleteAddressReducer: state.deleteAddressReducer,
  };
};
export const addAddressMapStateToProps = (state) => {
  return {
    addAddressReducer: state.addAddressReducer,
  };
};

export const clearAddressStateToProps = (state) => {
  return {
    addAddressReducer: state.addAddressReducer,
  };
};

export const editAddressMapStateToProps = (state) => {
  return {
    editAddressReducer: state.editAddressReducer,
  };
};

export const addHistoryAddressMapStateToProps = (state) => {
  // console.log("History data add :", state);
  return {
    addHistoryAddressReducer: state.addHistoryAddressReducer,
  };
};

export const showHistoryAddressMapStateToProps = (state) => {
  // console.log("History data show :", state);
  return {
    showHistoryAddressReducer: state.historyAddressReducer,
  };
};

export const previewListMapStateToProps = (state) => {
  //console.log('preview data show :', state)
  return {
    PreviewReducer: state.PreviewReducer,
  };
};
export const removepreviewListMapStateToProps = (state) => {
  // console.log("preview data show :", state);
  return {
    PreviewReducer: state.PreviewReducer,
  };
};

export const updatepreviewListMapStateToProps = (state) => {
  // console.log("preview data show :", state);
  return {
    updatePreviewReducer: state.updatePreviewReducer,
  };
};
// export const previewListMapStateToProps =(state)=>{
//   console.log('preview data show :', state)
//   return{
//     PreviewReducer: state.PreviewReducer
// }
// }

export const showFCMPushNotifications = (state) => {
  return {
    fcmPushNotificationReducer: state.fcmPushNotificationReducer,
  };
};

export const updateFCMNotification = (state) => {
  return {
    fcmPushNotificationReducer: state.fcmPushNotificationReducer,
  };
};

export const clearFCMNotification = (state) => {
  return {
    fcmPushNotificationReducer: state.fcmPushNotificationReducer,
  };
};
